import React from "react";
import { Box, Button, Container, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-scroll";

import { HomePageElements } from "../lib/constants";

import { ContactLink } from "./ContactLink";

import LogoDarkSvg from "../images/svg/LogoDark.svg";

const links = [
  HomePageElements.Why,
  HomePageElements.Hire,
  HomePageElements.BeHired,
  HomePageElements.Jobs,
  HomePageElements.Who,
];

export const Nav: React.FC = () => {
  return (
    <Box
      borderBottomWidth={1}
      borderBottomColor="gray.200"
      paddingY={4}
      paddingX={2}
      position="fixed"
      background="white"
      width="100%"
      zIndex={10}
    >
      <Container maxWidth="container.xl">
        <Stack direction="row" justify="space-between" align="center">
          <Stack direction="row" align="center">
            <LogoDarkSvg height={32} />
            <Text fontWeight="bold" fontSize="lg">
              teraDigital
            </Text>
          </Stack>
          <Stack direction="row" spacing={4}>
            <Stack
              direction="row"
              spacing={4}
              display={["none", "none", "flex"]}
            >
              {links.map((link) => (
                <Link smooth key={link} to={link} href={`#${link}`}>
                  <Button fontWeight="bold" variant="ghost">
                    {link}
                  </Button>
                </Link>
              ))}
            </Stack>
            <ContactLink>
              <Button fontWeight="bold" colorScheme="brand">
                CONTACT
              </Button>
            </ContactLink>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};
