import React, { ReactNode } from "react";
import { Box, BoxProps, Flex, useToken } from "@chakra-ui/react";

interface ExclaimProps extends BoxProps {
  topLeftAsset?: ReactNode;
  topRightAsset?: ReactNode;
  bottomLeftAsset?: ReactNode;
  bottomRightAsset?: ReactNode;
}

export const Exclaim: React.FC<ExclaimProps> = ({
  topLeftAsset,
  topRightAsset,
  bottomLeftAsset,
  bottomRightAsset,
  ...props
}) => {
  const brandColor = useToken("colors", "brand.500");
  return (
    <Box position="relative" paddingY={32}>
      {topLeftAsset && (
        <Box zIndex={0} position="absolute" bottom="60%" right="90%">
          {topLeftAsset}
        </Box>
      )}
      {topRightAsset && (
        <Box zIndex={0} position="absolute" bottom="60%" left="90%">
          {topRightAsset}
        </Box>
      )}
      {bottomLeftAsset && (
        <Box zIndex={0} position="absolute" top="60%" right="90%">
          {bottomLeftAsset}
        </Box>
      )}
      {bottomRightAsset && (
        <Box zIndex={0} position="absolute" top="60%" left="90%">
          {bottomRightAsset}
        </Box>
      )}
      <Flex
        borderRadius="sm"
        paddingY={[48, 48, 16]}
        paddingX={12}
        background={brandColor}
        color="white"
        justify="center"
        align="center"
        zIndex={1}
        position="relative"
        {...props}
      />
    </Box>
  );
};
