import * as React from "react";
import { Box, Button, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { Element } from "react-scroll";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import { Hero } from "../components/Hero";
import { Nav } from "../components/Nav";
import { Usp, Usps } from "../components/Usps";
import { Footer } from "../components/Footer";
import { ContactForm } from "../components/ContactForm";
import { SpecialistsExclaim } from "../components/SpecialistsExclaim";
import { HiringExclaim } from "../components/HiringExclaim";
import { NewsletterExlcaim } from "../components/NewsletterExclaim";
import { HomePageElements } from "../lib/constants";

import LogoSvg from "../images/svg/LogoLight.svg";
import IconLocation from "../images/svg/IconLocation.svg";
import IconStage from "../images/svg/IconStage.svg";
import IconExpertise from "../images/svg/IconExpertise.svg";
import IconNetwork from "../images/svg/IconNetwork.svg";
import IconRelationships from "../images/svg/IconRelationships.svg";
import IconUncover from "../images/svg/IconUncover.svg";
import { JobList } from "../components/JobList";
import { WhoDetails } from "../components/WhoDetails";
import { ContactLink } from "../components/ContactLink";

const hireUsps: Usp[] = [
  {
    title: "Australia Wide- Brisbane Based",
    description:
      "We partner with motivated organisations intent on securing key talent for web, digital & software product and application development teams.",
    icon: <IconLocation width="100%" />,
  },
  {
    title: "Any Stage",
    description:
      "teraDigital Services works with clients across the growth spectrum from startups and early stagers to mature enterprises - our solutions and deep market knowledge help attract great people.",
    icon: <IconStage width="100%" />,
  },
  {
    title: "Extensive Experience",
    description:
      "Utilising years of extensive, proven IT recruitment strategy and methodology to position our clients attractively in a competitive market, we maximise opportunities for qualified, best–fit candidate exposure.",
    icon: <IconExpertise width="100%" />,
  },
];

const beHiredUsps: Usp[] = [
  {
    title: "Talent Network",
    description:
      "Our talent network thrives via referral and recommendation. We are passionate service providers that support our technical and talent communities.",
    icon: <IconNetwork width="100%" />,
  },
  {
    title: "Real Relationships",
    description:
      "We invest heavily in building real relationships with great people- our candidates happily refer us colleagues, associates and friends.",
    icon: <IconRelationships width="100%" />,
  },
  {
    title: "Great Organisations",
    description:
      "teraDigital Services works hard to uncover great organisations and opportunites that propel careers.",
    icon: <IconUncover width="100%" />,
  },
];

const IndexPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const siteMetadata = data?.site?.siteMetadata || {};

  const heroActions = (
    <Stack direction="row" spacing={4}>
      <ContactLink>
        <Button size="lg" colorScheme="brand">
          Find Talent
        </Button>
      </ContactLink>
      <ContactLink>
        <Button size="lg" variant="outline" colorScheme="blackAlpha">
          Find Work
        </Button>
      </ContactLink>
    </Stack>
  );

  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />
        <link rel="canonical" href={siteMetadata.siteUrl} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={siteMetadata.title} />
        <meta property="og:description" content={siteMetadata.description} />
        <meta property="og:url" content={siteMetadata.siteUrl} />
        <meta property="og:site_name" content="teraDigital" />
        <script type="application/ld+json">{`{"@context": "http://schema.org", "@type": "WebSite", "url": "${siteMetadata.siteUrl}" }`}</script>
      </Helmet>
      <Nav />
      <Box as="main" paddingTop="72px">
        <Container maxWidth="container.lg" padding={8}>
          <Element name={HomePageElements.Why}>
            <Hero
              title="We navigate the maze of software talent for your business"
              subtitle={
                <Stack>
                  <Text>
                    teraDigital Services is a Specialist Recruiter and Software
                    Talent Aggregator based in Brisbane, servicing all of
                    Australia.
                  </Text>
                  <Text>We help build great software teams.</Text>
                </Stack>
              }
              asset={<LogoSvg style={{ maxHeight: 360 }} />}
              actions={heroActions}
            />
          </Element>

          <NewsletterExlcaim />

          <Element name={HomePageElements.Hire}>
            <Heading fontSize="5xl" paddingY={24}>
              HIRE
            </Heading>
            <Box paddingBottom={32}>
              <Usps usps={hireUsps} />
            </Box>
          </Element>

          <HiringExclaim />

          <Element name={HomePageElements.BeHired}>
            <Heading fontSize="5xl" paddingY={24}>
              BE HIRED
            </Heading>
            <Box paddingBottom={32}>
              <Usps usps={beHiredUsps} />
            </Box>
          </Element>

          <Element name={HomePageElements.Jobs}>
            <Heading fontSize="5xl" paddingY={24}>
              JOBS
            </Heading>
            <JobList />
          </Element>

          <SpecialistsExclaim />

          <Element name={HomePageElements.Who}>
            <Heading fontSize="5xl" paddingY={24}>
              WHO
            </Heading>
            <Box paddingBottom={32}>
              <WhoDetails />
            </Box>
          </Element>
        </Container>

        <Box background="gray.50" paddingBottom={24}>
          <Container maxWidth="container.lg" padding={8}>
            <Element name={HomePageElements.Contact}>
              <Heading fontSize="5xl" paddingY={24}>
                CONTACT
              </Heading>
              <Container maxWidth={["container.xl", "container.xl", 400]}>
                <ContactForm />
              </Container>
            </Element>
          </Container>
        </Box>

        <Footer />
      </Box>
    </Box>
  );
};

export default IndexPage;
