import React, { useState } from "react";
import {
  Stack,
  Input,
  Button,
  useBreakpoint,
  useToast,
  Collapse,
  Text,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";

import { FormsparkForms } from "../lib/constants";
import { submitForm } from "../lib/formspark";

interface NewsletterFormValues {
  email: string;
}

export const NewsletterForm: React.FC = () => {
  const { register, handleSubmit } = useForm<NewsletterFormValues>();

  const breakpoint = useBreakpoint() || "base";
  const isSmallScreen = ["base", "sm"].includes(breakpoint);

  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);

  const toast = useToast();

  const onSubmit: SubmitHandler<NewsletterFormValues> = async (values) => {
    setSaving(true);
    try {
      await submitForm(FormsparkForms.Newsletter, values);
      setSaved(true);
    } catch (e) {
      toast({
        status: "error",
        position: "bottom",
        variant: "top-accent",
        title: String(e),
      });
    }
    setSaving(false);
  };

  return (
    <>
      <Collapse animateOpacity in={!saved}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack width="full" direction="column" align="center">
            <Input
              width={["full", "full", "sm"]}
              placeholder="Enter your email address"
              background="white"
              color="gray.700"
              type="email"
              disabled={saving}
              {...register("email", { required: true })}
            />
            <Button
              isFullWidth={isSmallScreen}
              colorScheme="whiteAlpha"
              color="white"
              size="lg"
              variant="outline"
              type="submit"
              isLoading={saving}
            >
              SUBSCRIBE FOR UPDATES
            </Button>
          </Stack>
        </form>
      </Collapse>
      <Collapse animateOpacity in={saved}>
        <Stack align="center" spacing={8}>
          <Text fontSize="2xl" textAlign="center">
            <strong>Thankyou</strong>
            <br />
            We will be sure you keep you up to date.
          </Text>
        </Stack>
      </Collapse>
    </>
  );
};
