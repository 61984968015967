import axios from "axios";

import { FormsparkForms } from "./constants";

export const submitForm = <T = any>(form: FormsparkForms, values: T) => {
  const url = `https://submit-form.com/${form}`;
  return axios.request({
    url,
    method: "post",
    data: values,
  });
};
