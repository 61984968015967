import React, { useState } from "react";
import {
  Button,
  Collapse,
  FormControl,
  Text,
  FormLabel,
  Input,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";

import { FormsparkForms } from "../lib/constants";
import { submitForm } from "../lib/formspark";

import IconEmail from "../images/svg/IconEmail.svg";
import ShapeLines from "../images/svg/ShapeLines.svg";

interface ContactFormValues {
  reason: string;
  name: string;
  email: string;
  phone: string;
  organisation: string;
  message: string;
}

export const ContactForm: React.FC = () => {
  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);

  const toast = useToast();

  const onSubmit: SubmitHandler<ContactFormValues> = async (values) => {
    setSaving(true);
    try {
      await submitForm(FormsparkForms.Contact, values);
      setSaved(true);
    } catch (e) {
      toast({
        status: "error",
        position: "bottom",
        variant: "top-accent",
        title: String(e),
      });
    }
    setSaving(false);
  };

  const { register, handleSubmit } = useForm<ContactFormValues>();

  return (
    <>
      <Collapse animateOpacity in={!saved}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={6}>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                isDisabled={saving || saved}
                {...register("name", { required: true })}
                background="white"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input
                isDisabled={saving || saved}
                {...register("phone")}
                background="white"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                isDisabled={saving || saved}
                {...register("email", { required: true })}
                background="white"
                type="email"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Organisation</FormLabel>
              <Input
                isDisabled={saving || saved}
                {...register("organisation")}
                background="white"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Message</FormLabel>
              <Textarea
                isDisabled={saving || saved}
                resize="none"
                rows={6}
                {...register("message", { required: true })}
                background="white"
              />
            </FormControl>

            <Button
              variant="solid"
              colorScheme="brand"
              isLoading={saving}
              isDisabled={saving || saved}
              type="submit"
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Collapse>
      <Collapse animateOpacity in={saved}>
        <Stack align="center" spacing={8}>
          <IconEmail width="50%" />
          <Text fontSize="2xl" textAlign="center" paddingBottom={24}>
            <strong>Thankyou</strong>
            <br /> We have received your details and will be in contact with you
            shortly.
          </Text>
          <ShapeLines width="60%" />
        </Stack>
      </Collapse>
    </>
  );
};
