export enum HomePageElements {
  Why = "WHY",
  Hire = "HIRE",
  BeHired = "BE HIRED",
  Who = "WHO",
  Contact = "CONTACT",
  Jobs = "JOBS",
}

export enum FormsparkForms {
  Newsletter = "1glXIl6L",
  Contact = "h0CnK4T3",
}
