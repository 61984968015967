import React from "react";
import { Link } from "react-scroll";

import { HomePageElements } from "../lib/constants";

export const ContactLink: React.FC = (props) => (
  <Link
    smooth
    to={HomePageElements.Contact}
    href={`#${HomePageElements.Contact}`}
    {...props}
  />
);
