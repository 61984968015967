import React from "react";
import { Flex, List, ListItem, Stack, Text } from "@chakra-ui/layout";
import { FaRegCheckCircle } from "react-icons/fa";

import styled from "@emotion/styled";
import { useToken } from "@chakra-ui/system";

import { Usp, Usps } from "./Usps";

import IconTalent from "../images/svg/IconTalent.svg";

const whoUsps: Usp[] = [
  {
    title: (
      <Text color="gray.700">
        We attract and place talent across all product & application development
        functions
      </Text>
    ),
    icon: <IconTalent />,
  },
];

const FlexContainer = styled(Flex)`
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ListContainer = styled(List)`
  width: 100%;
  margin-top: 16px;
  .list-icon {
    font-size: 24px;
    margin-top: 8px;
  }
  @media (min-width: 768px) {
    width: 25%;
    .list-icon {
      font-size: 20px;
      margin-top: 5px;
    }
  }
`;

const items1 = [
  "Front End, Back End, Full Stack Developers",
  "Mobile Developers",
  "Software Engineers",
  "Automation & Test Functions",
  "Architects",
  "DevOps",
];

const items2 = [
  "Digital Designers",
  "UX/UI Folk",
  "Data Scientists",
  "Data Analysts",
  "Business Analysts",
];

const items3 = [
  "Team Leaders",
  "Scrum Masters",
  "Development Managers",
  "Project Managers",
  "Product Managers",
  "CTO/CIO",
  "Head of Functions",
];
//f99e49
export const WhoDetails: React.FC = () => {
  const orangeColor = useToken("colors", "orange.300");

  const renderList = (list: any[]) => (
    <ListContainer spacing={4}>
      {list.map((item, i) => (
        <ListItem key={i}>
          <Stack direction="row" spacing={[4, 4, 2]}>
            <FaRegCheckCircle color={orangeColor} className="list-icon" />
            <Flex flex={1}>
              <Text fontSize={["2xl", "2xl", "xl"]}>{item}</Text>
            </Flex>
          </Stack>
        </ListItem>
      ))}
    </ListContainer>
  );

  return (
    <Stack>
      <Usps usps={whoUsps} />
      <FlexContainer justify="space-between" paddingTop={8}>
        {renderList(items1)}
        {renderList(items2)}
        {renderList(items3)}
      </FlexContainer>
    </Stack>
  );
};
