import React from "react";
import { Stack, Heading, Button, useBreakpoint } from "@chakra-ui/react";
import { Link } from "react-scroll";

import { HomePageElements } from "../lib/constants";

import { Exclaim } from "./Exclaim";

import ShapeTrianglesSvg from "../images/svg/ShapeTriangles.svg";
import ShapeWavesSvg from "../images/svg/ShapeWaves.svg";

export const SpecialistsExclaim: React.FC = () => {
  const breakpoint = useBreakpoint() || "base";
  const isSmallScreen = ["base", "sm"].includes(breakpoint);

  return (
    <Exclaim
      topLeftAsset={<ShapeTrianglesSvg height={250} />}
      bottomRightAsset={<ShapeWavesSvg width={250} />}
    >
      <Stack direction="column" align="center" spacing={12} flex={1}>
        <Stack
          direction="column"
          align={["flex-start", "flex-start", "center"]}
          spacing={6}
        >
          <Heading fontSize={["3xl", "3xl", "4xl", "5xl"]}>
            WE ARE SPECIALISTS
          </Heading>
          <Heading
            fontSize={["lg", "lg", "xl", "2xl"]}
            fontWeight="regular"
            opacity={0.8}
          >
            We listen closely and help you fulfil your potential!
          </Heading>
        </Stack>
        <Link
          smooth
          to={HomePageElements.Contact}
          href={`#${HomePageElements.Contact}`}
        >
          <Button
            isFullWidth={isSmallScreen}
            colorScheme="whiteAlpha"
            color="white"
            size="lg"
            variant="outline"
          >
            JOIN OUR {isSmallScreen && <br />} TALENT NETWORK
          </Button>
        </Link>
      </Stack>
    </Exclaim>
  );
};
