import React, { useEffect, useState } from "react";
import { useSearchParam } from "react-use";
import { Center, Spinner } from "@chakra-ui/react";
import { scroller } from "react-scroll";
import styled from "@emotion/styled";

import { HomePageElements } from "../lib/constants";

import ShapeDottedPlusSvg from "../images/svg/ShapeDottedPlus.svg";

export const JobList: React.FC = () => {
  const job = useSearchParam("ja-job");
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      const jaJobsSettings = `
var _jaJobsSettings = {
  key: "AU2_t43vn6ymwvnerbe2n5pncx76re",
  applicationFormSettings: {
    useExternalApplicationForm: true,
    showExternalApplicationFormInNewWindow: false
  }
}`;
      const scriptSettings = document.createElement("script");
      scriptSettings.text = jaJobsSettings;
      document.body.appendChild(scriptSettings);
      const scriptImport = document.createElement("script");
      scriptImport.src = "//apps.jobadder.com/widgets/v1/jobs.min.js";
      scriptImport.async = true;
      document.body.appendChild(scriptImport);
      setLoaded(true);
    }
  }, [loaded]);

  useEffect(() => {
    console.log("updated");
    if (loaded && job) {
      scroller.scrollTo(HomePageElements.Jobs, {
        smooth: true,
      });
    }
  }, [job, loaded]);

  if (!loaded) {
    return (
      <Center>
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <JobListWrapper>
      <ShapeDottedPlusSvg id="shape-plus" />
      <div id="ja-jobs-widget"></div>
    </JobListWrapper>
  );
};

const JobListWrapper = styled.div`
  /* Jobs Widget */
  & {
    z-index: 1;
    background: white;
    border: 8px solid #333;
    border-radius: 2px;
    margin-bottom: 128px;
    position: relative;

    #shape-plus {
      width: 350px;
      position: absolute;
      bottom: 85%;
      left: 80%;
      z-index: 0;
    }
  }

  #ja-jobs-widget {
    padding: 48px;
    background: white;
    position: relative;
    @media (max-width: 768px) {
      padding: 24px;
    }
  }
  .ja-loading {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .ja-loading.left {
    left: 10px;
    right: auto;
  }
  /* Forms */
  .ja-form {
    margin-bottom: 3em;
  }
  .ja-form .ja-field-container {
    margin-bottom: 0.5em;
  }
  .ja-form .ja-field-container:before,
  .ja-form .ja-field-container:after {
    content: "";
    display: table;
  }
  .ja-form .ja-field-container:after {
    clear: both;
  }
  .ja-form .ja-field-container {
    zoom: 1;
  }
  .ja-form .ja-field-container label {
    display: block;
    float: left;
    width: 100px;
    padding-top: 6px;
  }
  .ja-form .ja-field-container .ja-field {
    float: left;
  }
  .ja-form .ja-field-container select,
  .ja-form .ja-field-container textarea,
  .ja-form .ja-field-container input[type="text"],
  .ja-form .ja-field-container input[type="password"],
  .ja-form .ja-field-container input[type="datetime"],
  .ja-form .ja-field-container input[type="datetime-local"],
  .ja-form .ja-field-container input[type="date"],
  .ja-form .ja-field-container input[type="month"],
  .ja-form .ja-field-container input[type="time"],
  .ja-form .ja-field-container input[type="week"],
  .ja-form .ja-field-container input[type="number"],
  .ja-form .ja-field-container input[type="email"],
  .ja-form .ja-field-container input[type="url"],
  .ja-form .ja-field-container input[type="search"],
  .ja-form .ja-field-container input[type="tel"],
  .ja-form .ja-field-container input[type="color"] {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1em;
    color: #333;
    border: solid 1px #ccc;
  }
  .ja-form .ja-field-container select {
    width: 240px;
    padding: 2px;
  }
  .ja-form .ja-field-container textarea {
    width: 230px;
    padding: 3px 4px;
  }
  .ja-form .ja-field-container input[type="text"],
  .ja-form .ja-field-container input[type="password"],
  .ja-form .ja-field-container input[type="datetime"],
  .ja-form .ja-field-container input[type="datetime-local"],
  .ja-form .ja-field-container input[type="date"],
  .ja-form .ja-field-container input[type="month"],
  .ja-form .ja-field-container input[type="time"],
  .ja-form .ja-field-container input[type="week"],
  .ja-form .ja-field-container input[type="number"],
  .ja-form .ja-field-container input[type="email"],
  .ja-form .ja-field-container input[type="url"],
  .ja-form .ja-field-container input[type="search"],
  .ja-form .ja-field-container input[type="tel"],
  .ja-form .ja-field-container input[type="color"] {
    width: 230px;
    padding: 3px 4px;
  }
  .ja-form .ja-field-container .checkbox:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  .ja-form .ja-field-container .checkbox {
    display: block;
    margin-left: 95px;
  }
  .ja-form .ja-field-container .checkbox br {
    clear: left;
  }
  .ja-form .ja-field-container .checkbox input {
    width: 15px;
    height: 19px;
    float: left;
  }
  .ja-form .ja-field-container .checkbox label {
    height: 21px;
    width: auto;
    line-height: 21px;
    float: left;
    padding: 2px 12px 0 5px;
  }
  .ja-form .ja-submit {
    margin-top: 1em;
    margin-left: 100px;
    vertical-align: middle;
  }
  .ja-form .ja-submit .ja-loading {
    position: relative;
    top: 0;
    left: 0;
    margin-left: 10px;
    vertical-align: middle;
  }
  .ja-button {
    font-size: 1em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: normal;
    border-radius: 2px;
    background: #1cb2e3;
    color: white;
    font-weight: bold;
    padding: 12px 24px;
  }
  .ja-button:hover {
    background: #168eb6;
    cursor: pointer !important;
  }
  .ja-button:active {
    position: relative;
    top: 1px;
  }
  .ja-form .ja-field-container .error {
    color: #990000;
    border-color: #990000;
  }
  .ja-form .ja-field-container .validation-error {
    display: block;
    clear: both;
    margin-top: 5px;
    color: #aa0000;
  }
  .ja-form .ja-error-container {
    color: #aa0000;
    margin-top: 2em;
    margin-left: 100px;
  }
  .ja-form .ja-error-container p {
    margin-bottom: 0;
  }
  /* End forms */
  /* Job list */

  .ja-job-list {
    margin-bottom: 3em;
  }
  .ja-job-list .job {
    margin-bottom: 1em;
    padding: 0.5em;
  }
  .ja-job-list .job.alt {
    background-color: #f5f5f5;
  }
  .ja-job-list .job.hot {
    background-color: #fffdfd;
  }
  .ja-job-list .job .title {
    font-weight: bold;
    font-size: 110%;
  }

  .ja-job-list .job .meta:before,
  .ja-job-list .job .meta:after {
    content: "";
    display: table;
  }
  .ja-job-list .job .meta:after {
    clear: both;
  }
  .ja-job-list .job .meta {
    zoom: 1;
  }
  .ja-job-list .job .meta p {
    font-size: 0.9em;
    margin-bottom: 0.5em;
  }
  .ja-job-list .job .meta .classifications {
    float: left;
    list-style: none;
    width: 50%;
    margin: 0 0 0.5em 0;
    color: #666;
  }
  .ja-job-list .job .meta .classifications li {
    display: inline;
    margin-right: 0.5em;
  }
  .ja-job-list .job .meta .date-posted {
    float: right;
    width: 50%;
    text-align: right;
  }
  .ja-job-list .job .meta .salary {
    clear: left;
    float: left;
  }
  .ja-job-list .job .meta .reference {
    float: right;
    text-align: right;
  }
  .ja-job-list .job .summary {
    margin-bottom: 8px;
  }
  .ja-job-list .job .view-details {
    font-size: 0.9em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: normal;
    border-radius: 2px;
    border: 1px solid #666666;
    padding: 4px 16px;
    text-decoration: none;
    color: #333333;
    display: inline-block;
  }
  .ja-job-list .job .view-details:hover {
    background-color: #eeeeee;
    cursor: pointer !important;
  }
  .ja-job-list .job .view-details:active {
    position: relative;
    background: #fafafa;
  }
  /* End job list */
  /* Pager */

  .ja-pager-container:before,
  .ja-pager-container:after {
    content: "";
    display: table;
  }
  .ja-pager-container:after {
    clear: both;
  }
  .ja-pager-container {
    zoom: 1;
  }
  .ja-pager {
    float: left;
    font-size: 0.9em;
  }
  .ja-pager:before,
  .ja-pager:after {
    content: "";
    display: table;
  }
  .ja-pager:after {
    clear: both;
  }
  .ja-pager {
    zoom: 1;
  }
  .ja-pager span,
  .ja-pager a {
    display: block;
    float: left;
    line-height: 1em;
    padding: 8px 12px;
    margin-right: 3px;
    color: #333;
    border: solid 1px #ccc;
  }
  .ja-pager a:hover {
    background: #eeeeee;
  }
  .ja-pager .current-page {
    color: #fff;
    background-color: #f6ad55;
    border-color: #f6ad55;
    text-decoration: none;
  }

  .ja-pager-summary {
    float: right;
    line-height: 25px;
    font-size: 0.9em;
  }
  /* End pager */
  /* Job details */

  .ja-job-details .title {
    font-weight: bold;
    font-size: 150%;
  }
  .ja-job-details .meta {
  }
  .ja-job-details .meta:before,
  .ja-job-details .meta:after {
    content: "";
    display: table;
  }
  .ja-job-details .meta:after {
    clear: both;
  }
  .ja-job-details .meta {
    color: #666;
    zoom: 1;
  }
  .ja-job-details .meta p {
    font-size: 0.9em;
    margin-bottom: 0.5em;
  }
  .ja-job-details .meta .classifications {
    float: left;
    width: 50%;
    list-style: none;
    margin: 0 0 0.5em 0;
  }
  .ja-job-details .meta .classifications li {
    display: inline;
    margin-right: 0.5em;
  }
  .ja-job-details .meta .date-posted {
    clear: right;
    float: right;
    width: 50%;
    text-align: right;
  }
  .ja-job-details .meta .salary {
    clear: left;
    float: left;
  }
  .ja-job-details .meta .reference {
    float: right;
    text-align: right;
  }
  .ja-job-details .bullet-points {
    padding-left: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .ja-job-details .apply {
    margin: 2em 0 0.5em 0;
    text-align: center;
  }
  .ja-job-details .apply .back-link {
    font-size: 0.9em;
    margin-right: 1em;
    color: #1cb2e3;
  }
  .ja-job-details .apply .back-link:hover {
    color: #168eb6;
  }

  /* End job details */
  /* Application form iframe (external app forms) */
  .ja-apply-iframe iframe {
    width: 100%;
    height: 500px;
    border: none;
  }
  .ja-apply-iframe .apply {
    margin: 2em 0 0.5em 0;
    text-align: center;
  }
  .ja-apply-iframe .apply .back-link {
    font-size: 0.9em;
  }
  /* End application form iframe */
  /* Application form */

  .ja-job-apply .ja-submit .back-link {
    font-size: 0.9em;
    margin-left: 1em;
  }
  /* End application form */
  /* Powered by JobAdder */
  .powered-by-jobadder {
    color: #bbb;
    font-size: 0.9em;
    text-align: center;
    margin: 2em 0 1em 0;
  }
  .powered-by-jobadder a {
    color: #bbb;
  }
  .powered-by-jobadder a:hover {
    color: #aaa;
  }
  /* End powered by JobAdder */
`;
