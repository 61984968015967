import React, { ReactNode } from "react";
import { Flex, Heading, Stack, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";

export interface Usp {
  title: ReactNode;
  description?: ReactNode;
  icon: ReactNode;
}

interface UspsProps {
  usps: Usp[];
}

const StackContainer = styled(Stack)`
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }

  .usp-icon {
    order: 0;
    margin-right: 24px;
  }
  .usp-text {
    order: 1;
  }

  @media (min-width: 768px) {
    &:nth-of-type(even) {
      .usp-icon {
        order: 1;
        margin-left: 24px;
      }
      .usp-text {
        order: 0;
      }
    }
  }
`;

export const Usps: React.FC<UspsProps> = ({ usps }) => {
  return (
    <Stack direction="column" spacing={24}>
      {usps.map((usp) => {
        return (
          <StackContainer key={String(usp.title)} spacing={8}>
            <Flex
              width={148}
              justify="center"
              className="usp-icon"
              align="center"
            >
              {usp.icon}
            </Flex>
            <Stack flex={1} justify="center" spacing={4} className="usp-text">
              <Heading as="h3" fontSize={"3xl"} color="orange.400">
                {usp.title}
              </Heading>
              <Text fontSize="xl" color="gray.600">
                {usp.description}
              </Text>
            </Stack>
          </StackContainer>
        );
      })}
    </Stack>
  );
};
